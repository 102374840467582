// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "trix";
import "@rails/actiontext";
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;
import "./controllers";
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

import Choices from "choices.js";
import flatpickr from "flatpickr";
import './loading_images.js';
import Seatchart from "./vendor/seatchart.js";
window.Seatchart = Seatchart;



$(document).on('turbo:load', function() {
  initializeFlashMessages();
  initializeTooltips();
  initializeChoices('select#article_tag_ids');
  initializeChoices('select#email_user_emails');
  initializeChoices('select#event_seat_map_ids', true);
  initializeSeatcharts();

  if ($('[data-behaviour="datetimepicker"]').length > 0) {
    flatpickr($('[data-behaviour="datetimepicker"]'), {
      enableTime: true,
      time_24hr: true,
      dateFormat: "d-m-Y H:i"
    });
  }

  $('.navbar-toggler').click(function () {
    toggleNavbarCollapseArea();
  });

  $(window).click(function(event) {
    if(!window.location.pathname.includes('admin')) {
      if (document.querySelector("#navbarSupportedContent").classList.contains('show')) {
        if (!event.target.matches('.navbar-collapse') && !event.target.matches('.navbar-collapse *')) {
          toggleNavbarCollapseArea();
        }
      }
    }
  });

  $(".event-button").on('click', function(event) {
    event.currentTarget.blur();
  });

  if ($('#carouselBannerImages').length > 0) {
    const myCarouselElement = document.querySelector('#carouselBannerImages')
    new bootstrap.Carousel(myCarouselElement, {
      interval: 4500,
    });
  };

  if ($('#loginModal').length > 0) {
    new bootstrap.Modal(document.getElementById('loginModal'), {});
  };

  let fileInputs = $("input[data-validation='maxFileSize']");
  if (fileInputs.length > 0) {
    let maxFileSize = 1 * 1024 * 1024; // 1MB
    let fileSizeValidationMsg = fileInputs[0].dataset.validationMessage;

    fileInputs.each(function () {
      this.addEventListener('change', (event) => {
        let target = event.target
        if (target.files && target.files[0]) {
          if (target.files[0].size > maxFileSize) {
            event.preventDefault();
            target.value = ''
            alert(fileSizeValidationMsg);
          }
        }
      })
    });

    document.addEventListener('trix-file-accept', function(event) {
      let fileSize = event.file.size;

      if (fileSize > maxFileSize) {
        event.preventDefault();
        alert(fileSizeValidationMsg);
      }
    });
  };

  const videos = document.querySelectorAll('video');

  videos.forEach(function(video) {
    video.addEventListener('loadedmetadata', function() {
      capturePoster(video);
    });

    video.addEventListener('error', function() {
      console.error('Video load error:', video.error);
    });
  });
});

function toggleNavbarCollapseArea() {
  const myCollapseElement = document.querySelector("#navbarSupportedContent");
  new bootstrap.Collapse(myCollapseElement).toggle();
};

function capturePoster(video) {
  var canvas = document.createElement('canvas');
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  var ctx = canvas.getContext('2d');
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

  var poster = canvas.toDataURL();
  video.setAttribute('poster', poster);
}

function initializeFlashMessages() {
  setTimeout(function() {
    $('.flash-message').alert('close');
  }, 10000);
}

function initializeChoices(selector, duplicationAllowed = false) {
  const element = document.querySelector(selector);
  if (element) {
    let optionsList = [];

    const choicesInstance = new Choices(element, {
      noChoicesText: 'Žádné možnosti k výběru',
      noResultsText: 'Žádné výsledky',
      allowHTML: true,
      removeItems: true,
      removeItemButton: true,
      duplicateItemsAllowed: duplicationAllowed,
      //renderSelectedChoices: 'always'
    });

    if (duplicationAllowed) {
      optionsList = JSON.parse(element.closest('.form-group').dataset.seatMapOptions || '[]');
      choicesInstance.clearChoices();
      choicesInstance.setChoices(optionsList, 'value', 'label', false);

      element.addEventListener('addItem', function (e) {
        choicesInstance.setChoices(
          [{ value: e.detail.value, label: e.detail.label }],
          'value',
          'label',
          false
        );
      });
      element.addEventListener('removeItem', () => resetChoices(choicesInstance, optionsList), false);
    }
  }
}

function resetChoices(choicesInstance, optionsList) {
  choicesInstance.clearChoices();
  choicesInstance.setChoices(optionsList, 'value', 'label', false);
}

function initializeTooltips() {
  const tooltipTriggerList = document.querySelectorAll('[data-toggle="tooltip"], [data-bs-toggle="tooltip"]');
  tooltipTriggerList.forEach((tooltipTriggerEl) => {
    new bootstrap.Tooltip(tooltipTriggerEl);
  });
}

function initializeSeatcharts() {
  if ($('div#mapContainer').length > 0) {
    new Seatchart(element, options);
    if ($('div[data-properties="sc-map-invisible"]').length > 0) {
      $('div[data-properties="sc-map-invisible"] .sc-right-container').hide();
      copyNicknamesFromTableToTooltips();
    }
  }
}

function copyNicknamesFromTableToTooltips() {
  let seatMap = {};

  $('table tr').each(function () {
    const nickname = $(this).find('td').eq(0).text().trim();
    const seat = $(this).find('td').eq(2).text().trim();

    if (nickname && seat) {
      seatMap[seat] = nickname;
    }
  });

  $('.sc-seat.sc-seat-reserved').each(function () {
    const seatId = $(this).text().trim();

    if (seatMap[seatId]) {
      const nickname = seatMap[seatId];
      $(this).attr({
        'data-bs-toggle': 'tooltip',
        'data-bs-placement': 'top',
        'aria-label': `Tady mačká: ${nickname}`,
        'data-bs-original-title': `Tady mačká: ${nickname}`
      });
    }
  });

  initializeTooltips();
}

$(window).scroll(function () {
  var window_top = $(window).scrollTop() + 1;
  if (window_top > 10) {
    $('.main-menu').addClass('scrolled');
    $('div.navbar-collapse').addClass('scrolled');
  } else {
    $('.main-menu').removeClass('scrolled');
    $('div.navbar-collapse').removeClass('scrolled');
  }
});

addEventListener("turbo:before-stream-render", (event) => {
  const originalRender = event.detail.render;

  event.detail.render = function (streamElement) {
    originalRender(streamElement);

    const afterRenderEvent = new CustomEvent("turbo:after-stream-render", {
      detail: {
        streamElement: streamElement
      }
    });
    document.dispatchEvent(afterRenderEvent);
  };
});

document.addEventListener("turbo:after-stream-render", function (event) {
  const targetElement = document.getElementById('flash-messages');
  const streamElement = event.detail.streamElement;

  if (targetElement && streamElement && targetElement.id === streamElement.target) {
    initializeFlashMessages();
  }
});

document.addEventListener("turbo:render", function() {
  initializeSeatcharts();
});
